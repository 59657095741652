import {
    elGetParents,
} from "../utils/element-helpers";

import {
    setCookie,
} from "../utils";

/**
 * Alerts
 * Remove button removes alert and adds cookie to prevent next render
 */
const alerts = () => {
    document.querySelectorAll("[data-remove-alert]").forEach(el => {
        const alertEl = elGetParents(el, ".alerts__item")[0];
        if(alertEl){
            const id = alertEl.getAttribute("id");
            el.addEventListener("click", () => {
                setCookie(id, "removed");
    
                alertEl.animate([
                    { height: "0px" },
                ], {
                    duration: 400,
                    easing: "ease-in-out",
                    interations: 10,
                }).onfinish = () => {
                    alertEl.remove();
                };
            });
        }

    });
}

document.addEventListener("DOMContentLoaded", alerts);

export default alerts;
