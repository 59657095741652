import {
    elSetCSS,
    elToggleClasses,
} from "../utils/element-helpers";

/**
 * Tooltip
 */
const tooltip = () => {
    const tooltipEl = document.querySelector(".tooltip");
    const triggerEls = document.querySelectorAll("[data-tooltip]");
    if (!triggerEls.length) return;

    triggerEls.forEach(el => {
        el.addEventListener("mousemove", ({ pageX:x, pageY:y }) => {
            elSetCSS(tooltipEl, {
                "top": y + "px",
                "left": x + "px",
            });
        });

        el.addEventListener("mouseover", ({ currentTarget }) => {
            tooltipEl.innerHTML = `<div>${currentTarget.getAttribute("data-tooltip")}</div>`;

            elToggleClasses(tooltipEl, {
                "--show": true,
            });
        });

        el.addEventListener("mouseout", () => {
            elToggleClasses(tooltipEl, {
                "--show": false,
            });
        });
    });
}

document.addEventListener("DOMContentLoaded", tooltip);

export default tooltip;
