const suburbPopup= () =>({
        init(){
            Alpine.store('global').suburbpopupshow = false;
            const closeTime = localStorage.getItem('suburbPopupClosedAt');
            const now = new Date().getTime();
            // if (!Alpine.store('global').selectedPostcode  && (!closeTime || now - closeTime > 24 * 60 * 60 * 1000)) {
            //      setTimeout(()=>{
            //         if(Alpine.store('global').forcsuburbpopupshow == false){
            //             Alpine.store('global').suburbpopupshow = true; // Delay popup to 0.5s
            //           }
            //        },500) 
            //        // Delay popup to 0.5s
            // }
        },
        handleClose() {
            localStorage.setItem('suburbPopupClosedAt', new Date().getTime());
            Alpine.store('global').suburbpopupshow = false;
        },
        handleSuburbChange(event) {
            // this.selectedSuburb = event.target.value;
        },
        handleSubmit($refs){
            Alpine.store('global').suburbpopuperror = false;
            if(!$refs.postcodequery.value){
                this.$refs.suburbpopuperrorref.textContent = "Please select a postcode";
                Alpine.store('global').suburbpopuperror = true;
            }else{
                Alpine.store('global').suburbpopuperror = false;
                // Validate and select 
                if( Alpine.store('global')?.foundpostcodes.length > 0){
                    Alpine.store('global')?.postcodeselectResult();
                }else{
                    // No content Found
                    this.$refs.suburbpopuperrorref.textContent = "Invalid postcode";
                    Alpine.store('global').suburbpopuperror = true;
                }
            }
        }
});


document.addEventListener("alpine:init", () => {
    Alpine.data("suburbPopup",suburbPopup);
});