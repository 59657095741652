// Alipine Store to manage pop, booknow for modal
document.addEventListener('alpine:init', async() => {
	Alpine.store('global', {
		form: document.querySelector(".bookNowForm"),
		suburbpopuperror:null,
		suburbpopupshow: false,
		forcsuburbpopupshow: false,
		booknowmodalopen:false,
		// Search Postcode 
		searchingpostocodes:false,
		foundpostcodes:  [],
		showsuburboptions:false,
		postcodequery: null,
		currentProfile: Alpine.$persist(null),
		selectedPostcode: Alpine.$persist(null).as('selected_postcode'),
		trackingData: Alpine.$persist([]),
		// Search Ahead function
		searchAheadLoading:false,
		searchNocontentFound:false,
		searchAheadKeywords:[],
		searchAheadcounts: 0,
		searchAheadResults: [],
		searchAheadHint:"",
		postcodeactiveIndex:null,
		setCookie(name, value, hours) {
			const date = new Date();
			date.setTime(date.getTime() + (hours * 60 * 60 * 1000)); // Convert hours to milliseconds
			const expires = "expires=" + date.toUTCString();
			document.cookie = name + "=" + value + ";" + expires + ";path=/";
		},
		getCookie(name) {
			const nameEQ = name + "=";
			const ca = document.cookie.split(';');
			for(let i = 0; i < ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) == ' ') c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
			}
			return null;
		},
		async handleDataRefresh(){
			// Handle tracking query params set to localstorage
			const paramsArray = [
				'utm_campaign',
				'utm_medium',
				'utm_source',
				'aol_campaign',
				'aol_order_id',
				'aol_offer',
				'aol_source',
				'aol_subsource',
				'utm_content',
				'utm_term',
				'gclid'
			];
			const urlParams = new URLSearchParams(window.location.search);
			let trackingValue = [];
			paramsArray.forEach(param => {
				if (urlParams.has(param)) {
					const value = urlParams.get(param);
					trackingValue.push({
						param, 
						value
					});
				}
			});
			if(trackingValue.length > 0){
				this.trackingData =  trackingValue;
			}
			try {
				const searchparams = new URLSearchParams(window.location.search);
				const urlgetpostcode = searchparams.get('postcode');
				if(urlgetpostcode){
					return;
				}
				const profileRefreshed = this.getCookie('profileRefreshed');
				if (profileRefreshed) {
					return;
				}
				if(this.currentProfile !== null){
                   await this.handleSelectedPostcode(this.currentProfile);
				   this.setCookie('profileRefreshed', 'true', 1); // gets new profile after an hour
				}
			} catch (error) {
				// Handle Error  -> Todo;
			}
		},
		async handlePostcodeRedirect(){
			// Logic for example:  /?postcode=5042
			try {
				const searchparams = new URLSearchParams(window.location.search);
				const urlgetpostcode = searchparams.get('postcode');
				if(!urlgetpostcode){
					this.forcsuburbpopupshow = false
					return;
				}
				// Validate postcode
				const foundtpost = await this.postcodeSearchBypostcode(urlgetpostcode);
				if(foundtpost?.status != 'success' && foundtpost?.data?.length <= 0) return;
				if(this.foundpostcodes?.length <= 0) return
				const firstdata = this.foundpostcodes[0];
				if(!firstdata) return;
				const setfoundpostcodeFirst = await this.handleSelectedPostcode(firstdata);
				this.forcsuburbpopupshow = true
			} catch (error) {
				// Handle Error  -> Todo;
			}
		},
		handletabsearchahead($refs){
			$refs.searchahead.value = $store.global.searchAheadHint;
			this.handlsearchAhead($refs);
		},
		postcodenextResult() {
			if (this.postcodeactiveIndex < this.foundpostcodes?.length - 1) {
				this.postcodeactiveIndex  = this.postcodeactiveIndex + 1;
			}
		},
		postcodeprevResult() {
			if (this.postcodeactiveIndex > 0) {
				this.postcodeactiveIndex = this.postcodeactiveIndex - 1
			}
		},
		postcodeselectResult($refs) {
			Alpine.store('global').suburbpopuperror = false;
			let index = this.postcodeactiveIndex ?? 0;
			if (index >= 0 && index < this.foundpostcodes?.length) {
				let selectpostcode = this.foundpostcodes[index];
				this.handleSelectedPostcode(selectpostcode,$refs);
			}
		},
		async handlsearchAhead($refs){
			try {
				// this.searchAheadKeywords = [];
				// this.searchAheadResults= [];
				// this.searchAheadcounts= 0;
				this.searchAheadLoading = false;
				this.searchNocontentFound = false;
				if($refs.searchahead?.value == ""){
					this.searchAheadHint =  "";
					this.searchAheadKeywords = [];
					this.searchAheadResults= [];
					this.searchAheadcounts= 0;
					return;
				}

				this.searchAheadLoading = true;

				const sanitizeQuery =  encodeURIComponent(`${$refs.searchahead?.value}`);
				const resp = await fetch(`/api/search?q=${sanitizeQuery}`);
				const data = await resp.json();


		

				this.searchAheadKeywords = data?.keywords ?? [];
				this.searchAheadResults = data?.data ?? [];
				this.searchAheadcounts = data?.count ?? 0;

				if(this.searchAheadResults?.length <=0){
					this.searchNocontentFound = true;
				}
				
				if($refs.searchahead?.value != ""  && data?.keywords[0]?.toLowerCase().startsWith($refs.searchahead?.value?.toLowerCase())){
					this.searchAheadHint = data?.keywords[0] ?? "";
				}else{
					this.searchAheadHint ="";
				}
			} catch (error) {
				// Handle Error  -> Todo
			}finally{
				this.searchAheadLoading = false;
			}
		},
		async handlsearchAheadByText(text){
			try {
				this.searchAheadLoading = false;
				this.searchNocontentFound = false;
				if(text == ""){
					this.searchAheadHint =  "";
					this.searchAheadKeywords = [];
					this.searchAheadResults= [];
					this.searchAheadcounts= 0;
					return;
				}
				this.searchAheadLoading = true;
				const sanitizeQuery =  encodeURIComponent(`${text}`);
				const resp = await fetch(`/api/search?q=${sanitizeQuery}`);
				const data = await resp.json();
				this.searchAheadKeywords = data?.keywords ?? [];
				this.searchAheadResults = data?.data ?? [];
				this.searchAheadcounts = data?.count ?? 0;
				if(this.searchAheadResults?.length <=0){
					this.searchNocontentFound = true;
				}
				if(text != ""  && data?.keywords[0]?.toLowerCase().startsWith(text?.toLowerCase())){
					this.searchAheadHint = data?.keywords[0] ?? "";
				}else{
					this.searchAheadHint ="";
				}
			} catch (error) {
				// Handle Error  -> Todo
			}finally{
				this.searchAheadLoading = false;
			}
		},
		async handleSelectedPostcode(item, $refs){
			try {
				this.currentProfile = item;
				const response = await fetch(item.profileUrl);
				const data = await response.json();
				const selectedpostcode = data?.data;
				this.selectedPostcode = selectedpostcode;
				this.closeSuburbPop();
			} catch (error) {
				// Handle Error  -> Todo
			}finally{
			}
		},
		async postcodeSearchBypostcode(postcode) {
			try {
				this.searchingpostocodes  = true;
				this.postcodeactiveIndex = null;
				const sanitizeQuery =  encodeURIComponent(`${postcode}`);
				const response = await fetch(`/api/postcode?q=${sanitizeQuery}`);
				const data = await response.json();
				if(data?.data){
				    const foundpostcodesArr = data?.data ?? [];
				   this.foundpostcodes = foundpostcodesArr;
				}
			} catch (error) {
				this.foundpostcodes = [];
			}finally{
				this.searchingpostocodes  = false;
			}
		},
		async postcodeSearch($refs) {
			this.foundpostcodes = [];

			if ($refs.postcodequery?.value && $refs.postcodequery.value?.length <= 3) {
				return;
			}
			if(!$refs.postcodequery?.value) return
			const getPoscodeNumberOnly = $refs.postcodequery?.value.match(/\d+/g).map(Number)[0];
			try {
				this.searchingpostocodes  = true;
				this.postcodeactiveIndex = null;
				const sanitizeQuery =  encodeURIComponent(`${getPoscodeNumberOnly}`);
				const response = await fetch(`/api/postcode?q=${sanitizeQuery}`);
				const data = await response.json();
				const foundpostcodesArr = data?.data ?? [];
				this.foundpostcodes = foundpostcodesArr;
			} catch (error) {
				this.foundpostcodes = [];
			}finally{
				this.searchingpostocodes  = false;
			}
		},
		openSuburbPop(){
			this.booknowmodalopen =  false;
			this.forcsuburbpopupshow = false
			localStorage.removeItem("suburbPopupClosedAt");
			if(!document.body.classList.contains('--showsuburbpopup'))  document.body.classList.add('--showsuburbpopup');
			this.suburbpopupshow = true
		},
		closeSuburbPop(){
			this.forcsuburbpopupshow = false
			if(document.body.classList.contains('--showsuburbpopup'))  document.body.classList.remove('--showsuburbpopup');
			this.suburbpopupshow = false
		},
		async toggelModal(){
			if(	document.querySelector('html').classList.contains('book-now-open')){
				document.querySelector('html').classList.remove('book-now-open');
			}	else{
				document.querySelector('html').classList.add('book-now-open');
			}	
			this.booknowmodalopen = !this.booknowmodalopen
		},
		async handleSelectOutside(){
			this.showsuburboptions = false;
		},
		prefillNamePhonne(bookname,bookphone,productid){
           const nameField = this.form.querySelector("input[name='fields[fullName]']");
           const phoneField = this.form.querySelector("input[name='fields[phoneNumber][number]'");
		//    const whatProductsFieldHidden = this.form.querySelector("input[name='fields[whatProducts]']");
		//    if(whatProductsFieldHidden){
		// 	whatProductsFieldHidden.value = productid.label;
		//    }
        //    const whatProductsField = this.form.querySelector("select[name='select4633']");
		//    if(whatProductsField){
		// 	 whatProductsField.value = productid.value;
		//    }
		   if(nameField){
			nameField.value = `${bookname}`;
		   }
		   if(phoneField) phoneField.value = `${bookphone}`;
		   this.toggelModalFooter();

		},
		toggelModalFooter(){
			// document.querySelector('html').classList.remove('is-scrolling--down');
			// document.querySelector('html').classList.add('is-scrolling--up');	
			if(	document.querySelector('html').classList.contains('book-now-open')){
				document.querySelector('html').classList.remove('book-now-open');
				this.booknowmodalopen = false
			}	else{
				document.querySelector('html').classList.add('is-scrolling--down');
				document.querySelector('html').classList.remove('is-scrolling--up');
				document.querySelector('html').classList.add('book-now-open');
				this.booknowmodalopen = true
			}	
		
		},
		bookmodelClose(){
			document.querySelector('html').classList.remove('book-now-open');
			this.booknowmodalopen = false
		}
	});

	await Alpine.store('global').handlePostcodeRedirect();
	await Alpine.store('global').handleDataRefresh();
})