import {
    elGetOffset,
    elSetCSS,
} from "../utils/element-helpers";

/**
 * Header
 * 
 * Sets MegaMenu indicator position and scale vars
 */
const header = () => {
    const headerEl = document.querySelector(".header");
    const megamenuEl = document.querySelector(".header__megamenu");
    const indicatorEl = megamenuEl.querySelector(".header__megamenu__indicator");
    let id = null;

    const onUpdate = () => {
        const activeEl = id == null ? null : document.querySelector(`#mm-${id}-trigger`);

        elSetCSS(indicatorEl, {
            "--x": (activeEl ? elGetOffset(activeEl).left - elGetOffset(megamenuEl).left + activeEl.offsetWidth / 2 : 0) + "px",
            "--scale": activeEl ? 1 : 0,
        });
    }

    headerEl.addEventListener("update", ({ detail }) => {
        id = detail;
        onUpdate();
    });

    window.addEventListener("resize", onUpdate);
}

document.addEventListener("DOMContentLoaded", header);

export default header;
