import {
    elToggleClasses,
} from "../utils/element-helpers";

/**
 * Element Query
 * v1.1.0
 * 
 * Sets modifier class to element based on specified breakpoints for the element's width
 * 
 * Usage:
 * Add data-el-query to element with json string with key "size" and value "class" (w/o --)
 * 
 * Example:
 * <div data-el-query='{"200":"sm","400":"md","800":"lg"}'></div>
 */
const elQuery = () => {
    const ATTR_EL = "data-el-query";
    let q, w, i, cur, next, more, less;

    const updateClasses = () => {
        document.querySelectorAll(`[${ATTR_EL}]`).forEach(el => {
            q = Object.entries(JSON.parse(el.getAttribute(ATTR_EL)));
            w = el.offsetWidth;

            i = 0;
            for (let [s, c] of q) {
                cur = parseInt(s);
                next = q[i+1] ? parseInt(q[i+1][0]) : null;
                more = w >= cur;
                less = next ? w < next : true;

                elToggleClasses(el, {
                    [`--${c}`]: more && less,
                });

                i++;
            }
        });
    };

    window.addEventListener("resize", updateClasses);
}

document.addEventListener("DOMContentLoaded", elQuery);

export default elQuery;
