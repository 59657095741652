// Vendor
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import persist from '@alpinejs/persist'
import mask from '@alpinejs/mask'
import collapse from '@alpinejs/collapse'
import anchor from '@alpinejs/anchor'
Alpine.plugin(focus);
Alpine.plugin(persist);
Alpine.plugin(mask);
Alpine.plugin(collapse);
Alpine.plugin(anchor);




import "lazysizes/plugins/respimg/ls.respimg"
import "lazysizes/plugins/parent-fit/ls.parent-fit"
import "lazysizes"
import "lazysizes/plugins/object-fit/ls.object-fit"

import { Fancybox } from "@fancyapps/ui"

// Modules
import "./modules/splide"
import "./modules/accordion"
import "./modules/alerts"
import "./modules/header"
import "./modules/el-copy"
import "./modules/el-query"
import "./modules/filters"
import "./modules/formie-extra"
import "./modules/google-maps"
// import "./modules/image-zoom"
import "./modules/marquee"
import "./modules/scroller"
import "./modules/svg-icon-sprite"
import "./modules/table-of-contents"
import "./modules/tooltip"
import "./modules/video-fit"
import "./modules/patches"
import "./modules/popover"
import "./modules/booknow"
import "./modules/global"

// On load
document.addEventListener("DOMContentLoaded", () => {
	const htmlEl = document.querySelector("html");

	setTimeout(() => {
		htmlEl.classList.remove("initiating");
		htmlEl.classList.add("init");
		window.dispatchEvent(new Event("resize"));

		// Book Now - Replace target with toggle functionality
		document.querySelectorAll(`a[href="#booknow"]`).forEach(el => {
			el.removeAttribute("href");
			el.setAttribute("x-on:click", "$store.global.toggelModal()");
		});

		// Identifies duplicate fancyboxes (generally rendered from slider clones) then
		// creates separate gallery ids before binding fancybox
		document.querySelectorAll("[data-fancybox]").forEach(el => {
			const id = el.getAttribute("data-fancybox");
			const src = el.getAttribute("href");
			document.querySelectorAll(`[data-fancybox="${id}"][href="${src}"]`).forEach((dup, index) => {
				dup.setAttribute("data-fancybox", `${id}-${index}`);
			});
		});

		// Bind Fancybox
		Fancybox.bind("[data-fancybox]", {});
	}, 100);
});


window.Alpine = Alpine;
window.Alpine.start();
