/**
 * Utilities
 */

const isDev = (process.env.NODE_ENV !== 'production');

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    return (parts.length === 2) ? parts.pop().split(';').shift() : null;
}

const setCookie = (name, value, days=365) => {
    let expires;
    if (days) {
        const d = new Date();
        d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `expires=${d.toUTCString()};`;
    }
    document.cookie = `${name}=${value};${expires}path=/`;
}

const kebabCase = string => string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

export {
    isDev,
    getCookie,
    setCookie,
    kebabCase,
};
