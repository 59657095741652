/**
 * Improve scroll performance on touch devices
 * set event listeners passive
 */
if ("ontouchstart" in document.documentElement) {
    document.addEventListener("touchstart", ontouchstart, { passive: true });
}

/**
 * Prevent keyboard scrolling
 */
window.addEventListener("keydown", e => {
    if(["ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
        e.preventDefault();
    }
}, false);

// Resolve Formie ARIA issues
setTimeout(() => {
    // aria-hidden with no value
    document.querySelectorAll(`[aria-hidden=""]`).forEach(el => {
        el.setAttribute("aria-hidden", "true");
    });
    // Country Select missing aria-label
    document.querySelectorAll(".iti__selected-flag").forEach(el => {
        el.setAttribute("aria-label", "Select country");
    });
    // Remove deprecated and failing aria attributes
    document.querySelectorAll('[aria-owns]').forEach(el => {
        el.removeAttribute("aria-owns");
    });
    document.querySelectorAll('[aria-activedescendant]').forEach(el => {
        el.removeAttribute("aria-activedescendant");
    });
}, 100);

// Resolve Splide ARIA role="group" issue. Just remove it.
setTimeout(() => {
    document.querySelectorAll(`.splide__slide[role="group"]`).forEach(el => {
        el.removeAttribute("role");
    });
    document.querySelectorAll(`.splide__slide[role="tabpanel"]`).forEach(el => {
        el.removeAttribute("role");
    });
}, 100);

/**
 * Alpine aria focus trap
 * Toggles tabindex of collapsed/expanded content
 */
document.addEventListener("DOMContentLoaded", () => {
    const els = document.querySelectorAll("[data-alpine-trap-trigger]");
    let contentEl, expanded;

    const hideCollapsed = () => {
        els.forEach(el => {
            contentEl = document.querySelector(`#${el.getAttribute("aria-controls")}`);
            expanded = el.getAttribute("aria-expanded") == "true";
            if(contentEl){
                contentEl.querySelectorAll("*").forEach(innerEl => {
                    innerEl.setAttribute("tabindex", expanded ? "" : "-1");
                });
            }
        });
    }
    hideCollapsed();

    els.forEach(el => {
        el.addEventListener("click", hideCollapsed);
    });

    window.addEventListener("keyup", ({ code }) => {
        if (code == "Escape") hideCollapsed();
    });
});
