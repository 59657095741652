import {
    elGetOffset,
    elSetCSS,
} from "../utils/element-helpers";

/**
 * El Copy
 * v1.1.1
 */
const elCopy = () => {
    const ATTR_SIZE_ITEM = "data-copy-size-item";
    const ATTR_SIZE_MIRROR = "data-copy-size-mirror";
    const ATTR_OFFSET_ITEM = "data-copy-offset-item";
    const ATTR_OFFSET_ITEM_CONTAINER = "data-copy-offset-item-container";
    const ATTR_OFFSET_MIRROR = "data-copy-offset-mirror";
    const ATTR_OFFSET_CONTAINER = "data-copy-offset-container";
    const sizeEls = document.querySelectorAll(`[${ ATTR_SIZE_ITEM }]`);
    const offsetEls = document.querySelectorAll(`[${ ATTR_OFFSET_ITEM }]`);

    sizeEls.forEach(el => {
        const id = el.getAttribute(ATTR_SIZE_ITEM);
        const mirrorEl = document.querySelector(`[${ ATTR_SIZE_MIRROR }="${ id }"]`);

        window.addEventListener("resize", () => {
            elSetCSS(mirrorEl, {
                width: el.offsetWidth + "px",
                height: el.offsetHeight + "px",
            });
        });
    });

    offsetEls.forEach(el => {
        const id = el.getAttribute(ATTR_OFFSET_ITEM);
        const idContainer = el.getAttribute(ATTR_OFFSET_ITEM_CONTAINER);
        const mirrorEl = document.querySelector(`[${ ATTR_OFFSET_MIRROR }="${ id }"]`);
        const containerEl = document.querySelector(`[${ ATTR_OFFSET_CONTAINER }="${ idContainer }"]`);

        window.addEventListener("resize", () => {
            elSetCSS(mirrorEl, {
                "margin-top": (elGetOffset(el).top - elGetOffset(containerEl).top) + "px",
            });
        });
    });
}

document.addEventListener("DOMContentLoaded", elCopy);

export default elCopy;
