/**
 * Table Of Contents
 * v1.1.0
 * 
 * Used in conjunction with Scroller
 */
const tableOfContents = () => {
    const ATTR_TOC = "data-toc";
    const tocEls = document.querySelectorAll(`[${ATTR_TOC}]`);

    tocEls.forEach(el => {
        const selectEl = el.querySelector("select");
        const scrollerEl = document.querySelector(`#${ el.getAttribute(ATTR_TOC) }`);

        selectEl.insertAdjacentHTML("beforeend", `
            <option value="">
                Select section
            </option>
        `);

        scrollerEl.addEventListener("scroller-sections-init", ({ detail: { sections } }) => {
            sections.forEach(({ id, label }) => {
                selectEl.insertAdjacentHTML("beforeend", `
                    <option value="${ id }">
                        ${ label }
                    </option>
                `);
            });

            selectEl.addEventListener("change", ({ currentTarget }) => {
                window.location = `#${ currentTarget.value }`;
            });
        });

        scrollerEl.addEventListener("scroller-section-update", ({ detail: { id } }) => {
            selectEl.value = id ?? "";
        });
    });
}

document.addEventListener("DOMContentLoaded", tableOfContents);

export default tableOfContents;
