import Alpine from "alpinejs";

const bookNow = ({currentStep,totalPages})=>({
    step: 1,
    success:false,
    loading:false,
    form: document.querySelector(".bookNowForm"),
    submitHandler: null,
    searchpostcodequerytext:'',
    totalPages:totalPages,
    validateSteps:[],
    init(){
        this.step = currentStep;
        // Check cookie exist dcid
        if(this.getCookie('_aol-dc-id') != null){
            const dcidField = this.form.querySelector("input[name='fields[dcid]']");
            if(dcidField){
                dcidField.value = this.getCookie('_aol-dc-id');
            }
        }

        if(this.getCookie('_fbc') != null){
            const fbcField = this.form.querySelector("input[name='fields[fbc]']");
            if(fbcField){
                fbcField.value = this.getCookie('_fbc');
            }
        }

        if(this.getCookie('_fbp') != null){
            const fbpField = this.form.querySelector("input[name='fields[fbp]']");
            if(fbpField){
                fbpField.value = this.getCookie('_fbp');
            }
        }
        document.addEventListener('onFormieInit', (e) => {
            let Formie = e.detail.formie;
            this.submitHandler  = Formie.getFormByHandle('bookNow');
            if(this.form){
                this.form.addEventListener("onBeforeFormieSubmit", this.onBeforeSubmit.bind(this));
                this.form.addEventListener("onAfterFormieSubmit", this.onAfterSubmit.bind(this));  
            }
        });

        this.$watch("Alpine.store('global')?.selectedPostcode", async (selectedpostcode) => {
            if(this.form){
                const stateField = this.form.querySelector("input[name='fields[stateCode]']");
                const stateSelecetField = this.form.querySelector("select[name='fields[stateCode]']");
                const suburbField = this.form.querySelector("input[name='fields[suburb]']");
                const postcodeField = this.form.querySelector("input[name='fields[postCode]']");
                const labsStateZoneId = this.form.querySelector("input[name='fields[labsStateZoneId]']");
                const labsStateCode = this.form.querySelector("input[name='fields[labsStateCode]']");
                if(stateSelecetField){
                    stateSelecetField.value = selectedpostcode?.suburb?.state;
                }
                if(suburbField){
                    suburbField.value = selectedpostcode?.suburb.name;
                }
                if(postcodeField){
                    postcodeField.value = selectedpostcode?.suburb.postcode;
                }
                if(labsStateZoneId){
                    labsStateZoneId.value = selectedpostcode?.location.labsStateZoneId;
                }
                if(labsStateCode){
                    labsStateCode.value = selectedpostcode?.location.labsStateCode;
                }
                if(stateField){
                    stateField.value = selectedpostcode?.suburb?.state
                };
                this.searchpostcodequerytext =  `${Alpine.store('global').selectedPostcode.suburb.postcode}, ${ Alpine.store('global').selectedPostcode.suburb.name}`;
            }
        });

        if(Alpine.store('global').selectedPostcode != null){
            const stateField = this.form.querySelector("input[name='fields[stateCode]']");
            const stateSelecetField = this.form.querySelector("select[name='fields[stateCode]']");
            const suburbField = this.form.querySelector("input[name='fields[suburb]']");
            const postcodeField = this.form.querySelector("input[name='fields[postCode]']");
            const labsStateZoneId = this.form.querySelector("input[name='fields[labsStateZoneId]']");
            const labsStateCode = this.form.querySelector("input[name='fields[labsStateCode]']");
            if(stateSelecetField){
                stateSelecetField.value = Alpine.store('global').selectedpostcode?.suburb?.state;
            }
            if(suburbField){
                suburbField.value = Alpine.store('global').selectedPostcode?.suburb.name;
            }
            if(postcodeField){
                postcodeField.value = Alpine.store('global').selectedPostcode?.suburb.postcode;
            }
            if(labsStateZoneId){
                labsStateZoneId.value = Alpine.store('global').selectedPostcode?.location.labsStateZoneId;
            }
            if(labsStateCode){
                labsStateCode.value = Alpine.store('global').selectedPostcode?.location.labsStateCode;
            }
            if(stateField){
                stateField.value = Alpine.store('global').selectedPostcode?.suburb?.state
            };
            this.searchpostcodequerytext =  `${Alpine.store('global').selectedPostcode.suburb.postcode}, ${ Alpine.store('global').selectedPostcode.suburb.name}`;
        }
    },
    handlessuboutsideclick(){
        // Made logic
        Alpine.store('global').foundpostcodes =   [];
 
        if(Alpine.store('global').selectedPostcode != null){
            const stateField = this.form.querySelector("input[name='fields[stateCode]']");
            const suburbField = this.form.querySelector("input[name='fields[suburb]']");
            const postcodeField = this.form.querySelector("input[name='fields[postCode]']");
            const labsStateZoneId = this.form.querySelector("input[name='fields[labsStateZoneId]']");
            const labsStateCode = this.form.querySelector("input[name='fields[labsStateCode]']");
            const stateSelecetField = this.form.querySelector("select[name='fields[stateCode]']");
            if(stateSelecetField && Alpine.store('global').selectedPostcode.suburb?.state){
                    stateSelecetField.value = Alpine.store('global').selectedPostcode.suburb?.state;
            }
            if(suburbField && Alpine.store('global').selectedPostcode?.suburb.name){
                    suburbField.value = Alpine.store('global').selectedPostcode?.suburb.name;
            }
            if(postcodeField &&  Alpine.store('global').selectedPostcode?.suburb.postcode){
                postcodeField.value = Alpine.store('global').selectedPostcode?.suburb.postcode;
            }
            if(labsStateZoneId && Alpine.store('global').selectedPostcode?.location.labsStateZoneId){
                labsStateZoneId.value = Alpine.store('global').selectedPostcode?.location.labsStateZoneId;
            }
            if(labsStateCode && Alpine.store('global').selectedPostcode?.location.labsStateCode){
                labsStateCode.value = Alpine.store('global').selectedPostcode?.location.labsStateCode;
            }
            if(stateField && Alpine.store('global').selectedPostcode?.suburb?.state){
                stateField.value = Alpine.store('global').selectedPostcode?.suburb?.state
            };
            this.searchpostcodequerytext =  `${Alpine.store('global').selectedPostcode.suburb.postcode}, ${ Alpine.store('global').selectedPostcode.suburb.name}`;
        }else{
            const stateField = this.form.querySelector("select[name='fields[stateCode]']");
            if(stateField){
                stateField.value = ''
            };
            this.searchpostcodequerytext = ''
        }
    },
    // Method 1: Formie way catching through events
    onAfterSubmit(e) {
        // Handle both back and submit action
        const validatenext = e.detail;
        // Validate steps
        if(!this.validateSteps.includes(this.step)){
            this.validateSteps.push(this.step);
        }
        if(validatenext?.success == true && validatenext?.isFinalPage == false){
            this.step = validatenext.nextPageIndex + 1;
            return;
        }
        // Handle Submit
        if(validatenext?.success == true && validatenext?.isFinalPage == true){
            this.success = true;
        }
    },
    onBeforeSubmit(e) {
        this.submitHandler = e.detail.submitHandler;
    },
    getCookie(name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    deleteCookie(name, path = '/', domain = null) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=' + path + 
                          (domain ? '; domain=' + domain : '') + ';';
    },
    togglebooknowClose($refs){
        window.location.reload();
        // this.success = false;
        // this.validateSteps = [];
        // Alpine.store('global').booknowmodalopen = false;
        // document.querySelector(".bookNowForm").reset();
        // this.init();
    },
     // Method 2:  Js way issue: doesn't catach all weired errors
    resetErrors(){
        const filedsErrors = this.form.querySelectorAll('.fui-errors');
        filedsErrors.forEach(field=>{
            if(field) field.parentNode.removeChild(field);
        })
    },
    displayFormErrors(errors) {
        Object.keys(errors).forEach((field) => {
            const fieldElement = this.form.querySelector(`[data-field-handle="${field}"]`);
            if (fieldElement ) {
                // This resets the error 
                if(fieldElement.classList.contains('fui-error')){
                    const filedError = fieldElement.querySelector('.fui-errors');
                    if(filedError) fieldElement.removeChild(filedError);
                }
                fieldElement.classList.add('fui-error');
                const errorElementW = document.createElement('div');
                errorElementW.classList.add('fui-errors');
                errorElementW.setAttribute('data-field-error-messages', '');
                const errorElement = document.createElement('div');
                errorElement.classList.add('fui-error-message');
                errorElement.setAttribute('data-field-error-message', '');
                errorElement.setAttribute('data-field-error-message-required', '');
                errorElement.setAttribute('aria-live', 'polite');
                errorElement.setAttribute('aria-atomic', 'true');
                errorElement.textContent = errors[field];
                errorElementW.appendChild(errorElement);
                fieldElement.appendChild(errorElementW);
            }
        });
    },
    async validateStep(next=true){
        try {
            const formData = new FormData(this.form);
            if(next){
                formData.append('submitAction',"submit")
            }else{
                formData.append('submitAction',"back")
            }
            const response = await fetch('/', {
                method: 'post',
                body: formData,
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            });
            const responsdata = response.json();
            return responsdata;
        } catch (error) {
            // InValidate Step
            this.validateSteps.pop(this.step);
            return false
        }
    },
    async gotoStep(step){
        this.loading = true;
        await this.validateStep(false)
        this.loading = false;
        this.step = step
    },
    async hanldeprev(e){
        e.preventDefault();
        this.loading  = true;
        const validPrev = await this.validateStep(false);
        this.loading  = false;
        if(validPrev.success == true){
            this.step--;
        }
    },
    async hanldenext(e){
        e.preventDefault();
        this.loading  = true;
        this.resetErrors()
        const validatenext = await  this.validateStep();
        if(validatenext != false && validatenext?.success  == false){
            // Show Errors
            this.displayFormErrors(validatenext.errors);
            return;
        }

        // Validate steps
        if(!this.validateSteps.includes(this.step)){
            this.validateSteps.push(this.step);
        }
        if(validatenext?.success == true && validatenext?.isFinalPage == false){
            this.step++;
            return;
        }
        // Handle Submit
        if(validatenext?.success == true && validatenext?.isFinalPage == true){
           this.success = true;
        }
    }
});

function customproductMultiSd({defaultOptions}) {
    return {
        form: document.querySelector(".bookNowForm"),
        options: defaultOptions ?? [],
        customproductmultiselectedValue: '',
        customproductmultiselectedOptions: [],
        init(){
            this.$watch("Alpine.store('global')?.selectedPostcode", async (selectedpostcode) => {
                this.resets();
                this.updateProductionOptions();
            });
            if(Alpine.store('global').selectedPostcode != null){
                this.resets();
                this.updateProductionOptions();
            }
            // watch selectedoptions and trigger formie checkbox
            this.$watch("customproductmultiselectedOptions", async (selectopts) => {
              const whatProductsField = this.form.querySelector("input[name='fields[whatProducts]']");
              const labProductIdsField =this.form.querySelector("input[name='fields[labsProductGeoZoneIds]']");
              if(whatProductsField){
                whatProductsField.value =  selectopts.map(i=>i.label).join(',');
              }
              if(labProductIdsField){
                labProductIdsField.value = selectopts.map(i=>i.labProductZeoId).join(',')
              }
            });
        },
        resets(){
            this.options = [];
            this.customproductmultiselectedValue=  '';
            this.customproductmultiselectedOptions = [];
            this.$refs.customproductmultidefaulslectedtref.textContent = `Select options`;
        },
        mappedProducts(products){
            const mappedOptions = products.filter(i=> i.level == 1)?.map(item =>{
                return {
                    label: item?.title,
                    value: `${item?.productId}`,
                    labProductZeoId:`${item?.labsProductGeoZoneId}`,
                    disabled: item?.availability?.status  == 'notAvailable' ?? false,
                }
            });
            return  mappedOptions;
        },
        updateProductionOptions(){
            const products = Alpine.store('global')?.selectedPostcode?.products ?? []; 
             if(products?.length > 0){
                const mappedProducts = this.mappedProducts(products);
                this.options = mappedProducts;
            }
        },
        customproductmultifilteredOptions() {
            return this.options;
        },
        selecteOptsTextUpdate(){
            if (this.customproductmultiselectedOptions.length < 4) {
                this.$refs.customproductmultidefaulslectedtref.textContent = `${this.customproductmultiselectedOptions.map(i => i.label).join(', ')}`;
            } else {
                this.$refs.customproductmultidefaulslectedtref.textContent = `Selected ${this.customproductmultiselectedOptions.length} products`;
            }
        },
        customproductmultiselectOption() {
            if (this.customproductmultiselectedValue) {
                this.customproductmultiselectedOptions= [];
                this.$refs.customproductmultidefaulslectedtref.textContent = `Select options`;
                
                const selectedOption = this.options.find(option => option.value == this.customproductmultiselectedValue);
                const notchecktSelectedOption =  selectedOption && !this.customproductmultiselectedOptions.some(option => option.value == selectedOption.value);

                // Single Select
                if(selectedOption){
                    this.customproductmultiselectedOptions = [selectedOption];
                    this.selecteOptsTextUpdate();
                    this.customproductmultiselectedValue = '';
                    return;
                }

                // Multiselecte
                if(!notchecktSelectedOption){
                    const filteredOpts = this.customproductmultiselectedOptions.filter(o => o.value !== selectedOption.value);
                    this.customproductmultiselectedOptions = filteredOpts;
                    if(this.customproductmultiselectedOptions.length > 0){
                        this.$refs.customproductmultidefaulslectedtref.textContent = `${filteredOpts.map(i => i.label).join(', ')}`;
                    }else{
                        this.$refs.customproductmultidefaulslectedtref.textContent = `Select options`;
                     }
                    this.customproductmultiselectedValue = '';  
                    return;
                }
                if (notchecktSelectedOption) {
                    this.customproductmultiselectedOptions.push(selectedOption);
                    this.selecteOptsTextUpdate();
                    this.customproductmultiselectedValue = '';
                }
            }
        },
    };
}

document.addEventListener("alpine:init", () => {
    Alpine.data("bookNow", bookNow);
    Alpine.data("customproductmulti", customproductMultiSd);
});