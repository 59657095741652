/**
 * Formie Extra
 * Injected overrides and other tweaks
 */
const formieExtra = () => {
    const htmlBtnCtaPrimary = `
        <button type="submit" data-submit-action="submit" class="button btn-default btn-cta-primary --cta --pill-icon-fill --force-interaction">
            <span class="button__content">
                <span class="button__text">
                    <span class="button__text__label">
                        label
                    </span>
                </span>

                <span class="icon icon-outline-arrow-right button__icon">
                    <svg><use xlink:href="#outline/arrow-right"></use></svg>
                </span>
            </span>
        </button>
    `;

    // replace formie submit with CTA Primary button
    document.querySelectorAll("[data-inject-button-cta-primary]").forEach(el => {
        const text = el.querySelector(".fui-submit").innerHTML;
        el.innerHTML = htmlBtnCtaPrimary;
        el.querySelector(".button__text__label").innerHTML = text;
    });
}

document.addEventListener("DOMContentLoaded", formieExtra);

export default formieExtra;
