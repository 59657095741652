/**
 * Filters
 * v1.0.0
 */

const filtersEl = document.querySelector("[data-filters]");

const getFilterData = (filterEls) => {
	let obj = {},
		values;

	filterEls.forEach(el => {
		const { type, name, value } = el;
		values = value;

		if (type == "radio" || type == "checkbox") {
			if (!obj[name]) {
				values = document.querySelectorAll(`input[name=${name}]:checked`)
					.map(inputEl => { return inputEl.value })
					.join(",");
			}
		} else if (el.getAttribute("data-multi-select") != undefined) {
			values = el.querySelectorAll("option[selected]")
				.map(optionEl => { return optionEl.value })
				.join(",");
		} else if (el.getAttribute("data-slider-input") != undefined) {
			values = el.value.split(" — ").join(",");
		}

		obj = {
			...obj,
			[name]: values,
		};
	});

	return obj;
};

// Convert object to query string helper
const obj2QueryString = obj => {
	return Object.keys(obj)
		.map(key => {
			return encodeURIComponent(key) + "=" + obj[key]
		})
		.join("&");
}
const queryString2Obj = (str) => {
	if (!str.length) return {};
	const arr = str.split("&");
	const obj = arr.reduce((o, v) => {
		o[v.split("=")[0]] = v.split("=")[1];
		return o;
	}, {});
	return obj;
}

// Ajax new content, and replace existing content
const getContent = filterData => {
	const data = filterData();
	const objQ = queryString2Obj(location.search.substring(1));
	const newQ = { ...objQ, ...data };

	// Update current filters
	const newLocation =
		location.origin +
		location.pathname +
		"?" +
		obj2QueryString(newQ);

	// TODO: LOW - REVIEW -- updating location
	// TODO: LOW - AJAX new content
	window.location = newLocation;
	return;
	fetch(newLocation)
		.then(response => {
			history.pushState("", "", newLocation);
			return response.text();
		})
		.then(data => {
			const oldListingContainer = document.querySelector(
				"[data-filtered-content]"
			);
			const parser = new DOMParser();
			const htmlResponse = parser.parseFromString(data, "text/html");
			const newListingContainer = htmlResponse.documentElement.querySelector(
				"[data-filtered-content]"
			);
			oldListingContainer.innerHTML = newListingContainer.innerHTML;
		})
		.catch(error => {
			// console.log("🤡");
			// console.log(error);
		})
}

const initFilters = el => {
	if (!el) return;

	const filterEls = el.querySelectorAll("select, input");
	if (!filterEls.length) return;

	const filterType = el.getAttribute("data-filters");
	const filterData = () => getFilterData(filterEls);

	const doFilter = () => {
		setTimeout(() => {
			getContent(filterData);
		}, 100);
	};

	switch (filterType) {
		case "submit":
			el.addEventListener("submit", doFilter);
			break;

		case "change":
			filterEls.forEach(el => {
				el.addEventListener("change", doFilter);
			});
			break;

		default:
			break;
	}
}

initFilters(filtersEl);
